<template>
    <div>
        <div class="main-wrap">
            <div class="header-wrapper pt-3 pb-0 shadow-none">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 navbar pt-0 pb-0">
                            <router-link to="/browse"><h1 class="fredoka-font ls-3 fw-700 text-current font-xxl">Elingway <span class="d-block font-xsssss ls-1 text-grey-500 open-font ">Learning Any Language Differently</span></h1></router-link>
                            <button @click=navToggle class="navbar-toggler" v-bind:class="{'collapsed': isNavShow}" type="button" :aria-expanded="isNavShow" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" v-bind:class="{'show': isNavShow}" id="navbarNavDropdown">
                                <ul class="navbar-nav nav-menu float-none text-center">
                                    <!-- <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Home <i class="ti-angle-down"></i></a>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">Home One</a>
                                            <a class="dropdown-item" href="#">Home Two</a>
                                            <a class="dropdown-item" href="#">Home Three</a>
                                            <a class="dropdown-item" href="#">Home Four</a>
                                            <a class="dropdown-item" href="#">Home Five</a>
                                            <a class="dropdown-item" href="#">Home Six</a>
                                            
                                            <a class="dropdown-item" href="#">Coming Soon</a>
                                            
                                        </div>
                                    
                                    </li>
                                    <li class="nav-item dropdown"><a class="nav-link  dropdown-toggle" data-toggle="dropdown" href="#">Pages <i class="ti-angle-down"></i></a>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">About</a>
                                            <a class="dropdown-item" href="#">Contact</a>
                                            <a class="dropdown-item" href="#">Contact 2</a>
                                            <a class="dropdown-item" href="#">404</a>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Blog <i class="ti-angle-down"></i></a>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">Blog Default</a>
                                            <a class="dropdown-item" href="#">Blog Sidebar</a>
                                            
                                            <a class="dropdown-item" href="#">Blog Single</a>
                                            
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Course <i class="ti-angle-down"></i></a>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="#">Course Gird 1</a>
                                            <a class="dropdown-item" href="#">Course Gird 2</a>
                                            <a class="dropdown-item" href="#">Course Gird 3</a>
                                            <a class="dropdown-item" href="#">Single Course 1</a>
                                            <a class="dropdown-item" href="#">Single Course 2</a>
                                            <a class="dropdown-item" href="#">User Profile</a>
                                            <a class="dropdown-item" href="#">Author Profile</a>
                                        </div>
                                    </li> -->
                                    <li class="nav-item"><router-link class="nav-link" to="/browse/courses">Course</router-link></li>
                                    <!-- <li class="nav-item"><a class="nav-link" href="#">About Us</a></li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 text-right d-none d-lg-block">
                    
                        </div>
                    </div>
                </div>
            </div>
            <slot/>
            <div class="footer-wrapper mt-0 bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-md-12 col-lg-4 col-sm-9 col-xs-12 md-mb-2">
                                    <a href="#"><h1 class="fredoka-font ls-3 fw-700 text-current font-xxl">Elingway <span class="d-block font-xsssss ls-1 text-grey-500 open-font ">Learning Any Language Differently</span></h1></a>
                                    <p class="w-100 mt-5">41 madison ave, floor 24 new work, <br>NY 10010 1-877-932-7111 <br> support@mail.com</p>
                                    <ul class="list-inline">
                                    <li class="list-inline-item mr-3"><a href="#"><i class="ti-facebook font-sm"></i></a></li>
                                    <li class="list-inline-item mr-3"><a href="#"><i class="ti-twitter-alt font-sm"></i></a></li>
                                    <li class="list-inline-item mr-3"><a href="#"><i class="ti-linkedin font-sm"></i></a></li>
                                    <li class="list-inline-item"><a href="#"><i class="ti-instagram font-sm"></i></a></li>
                                </ul>
                                </div>
                                <div class="col-md-3 col-lg-2 col-sm-3 col-xs-6 md-mb-2">
                                    <h5>Language</h5>
                                    <ul>
                                        <li><a href="#">English</a></li>
                                        <li><a href="#">Spanish</a></li>
                                        <li><a href="#">Arab</a></li>
                                        <li><a href="#">Urdu</a></li>
                                        <li><a href="#">Brazil</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-3 col-lg-2 col-sm-4 col-xs-6">
                                    <h5>Channel</h5>
                                    <ul>
                                        <li><a href="#">Makeup</a></li>
                                        <li><a href="#">Dresses</a></li>
                                        <li><a href="#">Girls</a></li>
                                        <li><a href="#">Sandals</a></li>
                                        <li><a href="#">Headphones</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-3 col-lg-2 col-sm-4 col-xs-6">
                                    <h5>About</h5>
                                    <ul>
                                        <li><a href="#">FAQ</a></li>
                                        <li><a href="#">Term of use</a></li>
                                        <li><a href="#">Privacy Policy</a></li>
                                        <li><a href="#">Feedback</a></li>
                                        <li><a href="#">Careers</a></li>
                                    </ul>
                                </div>
                                <div class="col-md-3 col-lg-2 col-sm-4 col-xs-6">
                                    <h5 class="mb-3">Office</h5>
                                    <p class="w-100">41 madison ave, floor 24 new work, NY 10010 <br>1-877-932-7111</p>
                                    <p class="w-100">41 madison ave, floor 24 new work, NY 10010 <br>1-877-932-7111</p>
                                </div>
                            </div>
                            <div class="middle-footer mt-5 pt-4"></div>
                        </div>
                        <div class="col-sm-12 lower-footer pt-0"></div>
                        <div class="col-sm-6 col-xs-12">
                            <p class="copyright-text">© 2021 copyright. All rights reserved.</p>
                        </div>
                        <div class="col-sm-6 col-xs-12 text-right">
                            <p class="copyright-text float-right">Design by <a href="#" class="">uitheme</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal bottom fade" id="Modalvideo" tabindex="-1" role="dialog">
            <div class="modal-dialog video-wrap modal-dialog-centered" role="document">
                <div class="modal-content border-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="ti-close text-grey-500"></i></button>
                    <div class="modal-body p-3 d-flex align-items-center bg-none">
                        <div class="embed-responsive embed-responsive-16by9">
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default ({
    name: 'GuestLayout',
    data() {
        return {
            isNavShow: false
        }
    },
    methods: {
        navToggle() {
            this.isNavShow = !this.isNavShow
        }
    }
})
</script>
